<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form#FormInquiryAddressChange(:endpoint='`/_/${context}/${id}`' updateOnSubmitSuccess='AddNewAddress' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header(class='mb-8' @cancel='$emit("close")')
        | {{ $t(`Select {0} for inquiry`, '', [$t(type)]) }}
        template(#below)
          p(
            v-add-element-attributes='{ a: { href: $router.resolve({ name: "customer-addresses" }).href } }'
            class='text-text-light'
            v-html='$t("Company addresses can be edited in the <a>Company Profile</a>.")'
          )
      template(v-if='config.items[type].options && config.items[type].options.length > 0')
        form-radio-group(:name='type' class='mb-4')
          control-radio-item(v-for='{ label, value } in config.items[type].options' :key='value' :value='value' :bordered='true' :centered='true' size='large')
            span(v-html='format(label)')
        form-footer
          form-action(:primary='true' :name='`Set${type}`')
          template(#secondary)
            btn#btnAddressAdd(secondary icon='plus' :scaledIcon='true' @click='$utils.routeQueryAdd({ "form-inquiry-address-add": { id, title, context, type } })') {{ $t('New Address') }}
</template>

<script lang="ts">
import ControlRadioItem from '@/components/Control/ControlRadioItem.vue'
import { useOnSuccess } from '@/composables/'
import { defineComponent, type SetupContext } from 'vue'

const FormInquiryAddressChange = defineComponent({
  components: { ControlRadioItem },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    title: { type: String, required: true },
    context: { type: String, required: true },
  },
  emits: ['close'],
  setup(props, context: SetupContext) {
    // @TODO: find better solution for concurrent routerQueryRemove calls that happen without timeout
    useOnSuccess('AddNewAddress', () => setTimeout(() => context.emit('close'), 100))
    return {
      format: (label: string) => {
        const [title, ...other] = label.split(' | ')
        return `<b>${title}</b><br><span>${other.join(', ')}</span>`
      },
    }
  },
})
export default FormInquiryAddressChange
</script>
